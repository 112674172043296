import React from 'react';
import {makeStyles} from '@material-ui/core';
// noinspection ES6CheckImport
import {withRouter} from 'react-router-dom';

import Header from 'appLayout/components/header/Header';

import {NAVPATH} from 'constants/navigation';
import {SidebarProvider} from './components/sidebar/SideBarContextProvider';

export const headerHeight = 20;

const useAppLayoutStyles = makeStyles({
    layout: {
        boxSizing: 'border-box',
        margin: '0px',
        minWidth: '1280px',
        overflow: 'hidden',
    },
    header: {
        width: '100%',
        height: '159px',
        backgroundColor: 'white',
    },
    body: {
        position: 'fixed',
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 159px)',
    },
    content: {
        width: '100%',
        overflow: 'auto',
        height: '100%'
    }
});

const renderSidebarOnPaths = [
    NAVPATH.STUDY_ADVICE,
    NAVPATH.RESOURCES__FAST_FACTS,
    NAVPATH.SAQS__BY_SYLLABUS,
    NAVPATH.SAQS__BY_PAPER,
    NAVPATH.SYLLABUS__PHARMACOLOGY,
    NAVPATH.SYLLABUS__PHYSIOLOGY,
];

const AppLayoutX = ({history, location, children}) => {
    const styles = useAppLayoutStyles();
    const isLoginSuccessPage = location.pathname.includes('/login/success')
    return (
        <div className={styles.layout}>
            {
                !isLoginSuccessPage ? (
                        <>
                            <div className={styles.header}>
                                <Header history={history} headerHeight={headerHeight}/>
                            </div>
                            <div className={styles.body}>
                                <SidebarProvider
                                    currentPathName={location.pathname}
                                    renderSidebarOnPaths={renderSidebarOnPaths}
                                >
                                    <div className={styles.content}>{children}</div>
                                </SidebarProvider>
                            </div>
                        </>)
                    :
                    (
                        <>
                            <SidebarProvider
                                currentPathName={location.pathname}
                                renderSidebarOnPaths={renderSidebarOnPaths}
                            >
                                <div className={styles.content}>{children}</div>
                            </SidebarProvider>
                        </>
                    )

            }

        </div>
    );
};

export const AppLayout = withRouter(AppLayoutX);
